import React, { useContext } from "react";
import { PurchasesContext } from "../../context/PurchasesContext";
import { ModalContext } from "../../context/ModalContext";
import { UserContext } from "../../context/UserContext";
import EditPurchaseForm from "./EditPurchaseForm";
import StatusBadge from "../common/StatusBadge";
import { formatMonto } from "../../utils";
import { Link } from "@reach/router";
import moment from "moment";

const PurchaseRow = ({ purchase, showType }) => {
  const { user } = useContext(UserContext);
  const { modalComponent } = useContext(ModalContext);
  const { setPurchase } = useContext(PurchasesContext);

  const handleEdit = () => {
    setPurchase(purchase);
    modalComponent("Editar Compra", <EditPurchaseForm />);
  };

  const canEdit = () => {
    return (
      purchase.status !== "revoked" && ["admin", "manager"].includes(user.role)
    );
  };

  const renderActions = () => {
    if (canEdit()) {
      return (
        <button
          className="btn btn-sm btn-outline-dark me-2"
          onClick={handleEdit}
        >
          <i className="fa fa-edit"></i>
        </button>
      );
    }
  };

  const renderCustomer = () => {
    if (purchase.user && purchase.user !== null) {
      return (
        <td>
          <Link to={`/myadmin/customer/${purchase.user.user_id}`}>
            <i className="fa fa-eye"></i> {purchase.user.name}{" "}
            {purchase.user.last_name}
          </Link>
        </td>
      );
    }
  };

  const isFirstPurchase = () => {
    if (purchase.user && purchase.user !== null) {
      if (Array.isArray(purchase.user.invoices))
        return purchase.user.invoices.length === 1;
    }
  };

  const renderDiscountCode = () => {
    if (purchase.discount && purchase.discount !== null) {
      return purchase.discount.code;
    }
  };

  const renderClassPackage = () => {
    if (purchase.course_purchase && purchase.course_purchase !== null) {
      if (
        purchase.course_purchase.course &&
        purchase.course_purchase.course !== null
      ) {
        return purchase.course_purchase.course.name;
      }
    }
    if (
      parseInt(purchase.amount) === 800 ||
      parseInt(purchase.amount) === 1300
    ) {
      return "How To Latina";
    }
    if (
      parseInt(purchase.amount) === 3000 ||
      parseInt(purchase.amount) === 15900 ||
      parseInt(purchase.amount) === 6450
    ) {
      return "Certificación";
    }
  };

  const renderPaymentMethod = () => {
    let { payment_method_id, order_id } = purchase;
    switch (payment_method_id) {
      case 1:
        return (
          <a
            href={`https://panel-beta.conekta.com/transactions/payments/${order_id}`}
            target="_blank"
          >
            Conekta
          </a>
        );
      case 2:
        return <span>Transferencia/Depósito</span>;
      case 3:
        if (purchase.invoice_id) {
          return (
            <a
              href={`https://dashboard.stripe.com/invoices/${order_id}`}
              target="_blank"
            >
              Stripe
            </a>
          );
        }
        return (
          <a
            href={`https://dashboard.stripe.com/subscriptions/${order_id}`}
            target="_blank"
          >
            Stripe
          </a>
        );
      case 4:
        return (
          <a
            href={`https://www.paypal.com/activity/payment/${order_id}`}
            target="_blank"
          >
            PayPal
          </a>
        );
    }
  };

  const renderType = () => {
    if (showType) {
      return (
        <td>
          {purchase.invoice_id && purchase.invoice_id !== null
            ? "Cargo"
            : "Compra"}
        </td>
      );
    }
  };

  return (
    <tr className="small border-bottom align-middle hover-light">
      <td>
        {purchase.invoice_id ? purchase.invoice_id : purchase.purchase_id}{" "}
        {purchase.admin_enabled && <i className="fa fa fa-user-shield"></i>}{" "}
        {isFirstPurchase() && <i className="fa fa-star text-warning"></i>}
      </td>
      {renderType()}
      {renderCustomer()}
      <td>{renderClassPackage()}</td>
      <td>{moment(purchase.createdAt).format("DD MMM YYYY HH:mm")}</td>
      <td>
        {purchase.is_gift && <i className="fas fa-gift me-1"></i>}
        {"$"}
        {formatMonto(purchase.amount)} MXN
      </td>
      <td>{renderPaymentMethod()}</td>
      <td>
        <StatusBadge
          order_id={purchase.order_id}
          status={purchase.status}
          date={purchase.updatedAt}
        />
      </td>
      <td>{renderDiscountCode()}</td>
      <td>{renderActions()}</td>
    </tr>
  );
};

export default PurchaseRow;
