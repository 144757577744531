import React, { useContext } from "react";
import { Link } from "@reach/router";
import { formatMonto } from "../../utils";
import moment from "moment";
import StatusBadge from "../common/StatusBadge";
import InvoiceForm from "./InvoiceForm";
import { InvoicesContext } from "../../context/InvoicesContext";
import { ModalContext } from "../../context/ModalContext";

const InvoiceRow = ({ user, invoice, customer_id }) => {
  const { setInvoice } = useContext(InvoicesContext);
  const { modalComponent } = useContext(ModalContext);

  const renderPaymentMethod = () => {
    if (String(invoice.payment_processor_id).startsWith("in_")) {
      return (
        <a
          href={`https://dashboard.stripe.com/invoices/${invoice.payment_processor_id}`}
          target="_blank"
        >
          Stripe
        </a>
      );
    }
    if (invoice.payment_method && invoice.payment_method !== null) {
      return invoice.payment_method.name;
    }
    return "N/A";
  };

  const handleEdit = () => {
    setInvoice(invoice);
    modalComponent("Editar Cargo", <InvoiceForm />);
  };

  const renderCourse = () => {
    if (invoice.purchase && invoice.purchase !== null) {
      let course_purchase = invoice.purchase.course_purchase;
      if (course_purchase && course_purchase !== null) {
        let course = course_purchase.course;
        if (course && course !== null) {
          return course.name;
        }
      }
    }
  };

  const renderActions = () => {
    if (user.role === "admin") {
      return (
        <button className="btn btn-sm btn-outline-dark" onClick={handleEdit}>
          <i className="fa fa-edit"></i>
        </button>
      );
    }
  };

  return (
    <tr className="hover-light border-bottom small align-middle">
      <td className="id-td">{invoice.invoice_id}</td>
      <td className="id-td">
        {invoice.purchase_id !== null && invoice.purchase !== null
          ? invoice.purchase_id
          : "N/A"}
      </td>
      <td>{moment(invoice.createdAt).format("DD MMM YYYY HH:mm")}</td>
      {!customer_id && (
        <td>
          <Link to={`/myadmin/customer/${invoice.customer_id}`}>
            {invoice.customer.name} {invoice.customer.last_name}{" "}
          </Link>
        </td>
      )}
      <td>{renderCourse()}</td>
      <td>
        {user.role === "admin" && (
          <>
            {"$"}
            {formatMonto(invoice.amount)}
          </>
        )}
      </td>
      <td>
        <StatusBadge status={invoice.status} />
      </td>
      <td>{renderPaymentMethod()}</td>
      <td>{renderActions()}</td>
    </tr>
  );
};

export default InvoiceRow;
